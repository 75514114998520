import { Observable } from 'rxjs';
import { Address } from './users';
export interface Project {
    _id: any;
    name: string;
    description: string;
    builderName: string;
    address: Address;
    location: string;
    map: string;
    tagLine: string;
    contact: {
        name: string;
        title: string;
        phone: string;
    };
    webSite: string;
    status: boolean;
    gallery: {
        coverImg: String;
        images: Array<String>;
    };
    sitePlan: {
        mapSvg: string;
        lotSelected: number;
        lots: Array<Lots>;
        imagePlanPath: string;
        modeClustersLots: boolean;
        clusterSelected: number;
        clusterImagenPlanPath: string;
        clusterMapSvg: string;
        clusterMapLotsSvg: string;
        clusterLots: Array<Lots>;
        clusters: {
          id: string;
          clusterName: string;
          available: boolean;
          type: string;
          lotSeletec: number;
          mapSvg: string;
          imagePlanPath: string;
          lots: Array<Lots>;
        }[];
    };
    modelActive: number;
    models: Array<Models>;
    defaultView: string;
}
export interface Lots {
    id: string;
    available: boolean;
    size: {
      value: number;
      unit: string;
    }
    type: string;
    modelsAvailables: Array<ModelsAvailables>;
    lotPrice: string;
}
export interface ModelsAvailables {
  id: number;
}
export interface Layers {
    level: number;
    itemActive: number;
    itemDefaultName: string;
    itemDefaultCost: number;
    itemDefaultThumbnail: string;
    items:
    {
        name: string;
        cost: number;
        thumbnail: string;
        pathLayerPicture: string;
    }[];
}
export interface Options {
    id: string;
    name: string;
    description: string;
    pathBasePicture: string;
    subOptions: {
        id: string;
        name: string;
        layers: Array<Layers>;
    }[];
}
export interface Models {
    id: string;
    name: string;
    description: string;
    coverPicture: string;
    modelPrice: string;
    views: {
        id: string;
        name: string;
        subView: {
            id: string;
            name: string;
            options: Array<Options>;
        }[];
    }[];
}

export const buttonTitle = {
    views: [
      {
        activeView: "Exterior",
        subViewName: "View",
        optionName: "Option"
      },
      {
        activeView: "Interior",
        subViewName: "Space",
        optionName: "Option"
      },
      {
        activeView: "Floor Plan",
        subViewName: "Level",
        optionName: "Option"
      },
      {
        activeView: "360 View",
        subViewName: "Space",
        optionName: "Option"
      },
      {
        activeView: "Site Plan",
        subViewName: "Plat",
        optionName: "Lot"
      }
    ]
  };

export interface CheckListProject {
    models?: Number, 
    sitePlan?: Boolean, 
    modelsViews?: {
      exterior?: Boolean, 
      interior?: Boolean, 
      floorPlan?: Boolean, 
      three60?: Boolean, 
      animatedTour?: Boolean,
      panoramaView?: Boolean
    }[]
}
export abstract class ProjectData {
    abstract getAll(): Observable<Project[]>;
    abstract getCurrentProject(): Observable<Project> ;
    abstract get(id: number): Observable<any>;
    abstract update(project: Project): Observable<Project>;
    abstract updateCurrent(project: Project): Observable<Project>;
    // abstract create(user: Project): Observable<Project>;
    // abstract delete(id: number): Observable<boolean>;
  }
